* {
  font-family: "Courier New", Courier, monospace;
}

body {
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 18px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  box-shadow: inset 0 0 0 10px;
  cursor: pointer;
}

.data-form-structure,
.data-table {
  border: 1px solid red;

  .data-form-header {
    background-color: purple;
    color: white;
    padding: 20px;
    font-size: 17px;
  }
  form {
    padding: 20px;
  }

  .ant-table-wrapper {
    padding: 0!important;

    #active-span,
    #inactive-span {
      padding: 5px 10px;
      color: white;
      border-radius: 5px;
    }

    #active-span {
      background-color: green !important;
    }
    #inactive-span {
      background-color: #dc3546;
    }
    .actions {
      display: flex;
      align-items: center;
      .delete-button,
      .update-button,
      .detail-button {
        color: white;
        font-weight: 500 !important;
        padding: 7px 10px !important;
        border-radius: 5px;
        font-size: 14px;
        border: none;
        text-decoration: none;
        transition: all 0.4s;
        display: inline-block;
        line-height: 1.2;
        cursor: pointer;
      }
      .detail-button {
        background-color: #042e5acc;
        &:hover {
          background-color: #042d5a;
        }
      }
      .delete-button {
        background-color: rgba($color: #dc3546, $alpha: 0.75);
        margin-inline: 5px;

        &:hover {
          background-color: #dc3546;
        }
      }

      .update-button {
        background-color: rgba($color: #ec7424, $alpha: 0.75);

        &:hover {
          background-color: #ec7424;
        }
      }
    }
  }

  .user-detail {
    padding: 20px 10%;
    .user-detail-row {
      display: flex;
      p {
        width: 50%;
        span {
          font-weight: bold;
        }
      }
    }
  }
}

// DataTable

.ant-table-wrapper {
  min-width: 100%;
  overflow-x: scroll;

  .ant-table-tbody {
    background-color: white;
  }

  #active-span,
  #inactive-span {
    padding: 5px 10px;
    color: white;
    border-radius: 5px;
  }

  #active-span {
    background-color: green !important;
  }
  #inactive-span {
    background-color: #dc3546;
  }
}

@media only screen and (max-width: 600px) {
  .ant-table-wrapper {
    min-width: 100%;
    overflow-x: scroll;

    .actions {
      .delete-button,
      .update-button,
      .detail-button {
        padding: 5px 7px !important;
        font-size: 10px!important;
      }
    }

    th,
    td {
      font-size: 10px !important;
    }
  }

  .data-form-structure {
    .ant-form-item  {  
      font-size: 12px!important;
        label, input, select, button, span{
          font-size: 12px!important;
        }
        optgroup { font-size:40px; }
    }
  }
}
